import React from 'react';
import './Content.css';
import Helpers from './Helpers';

class Content extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang : "de",
			dictionary : {},

			items: [],
			yrs : {},

			types : [],
			years : [],

			all_years : false,
			
			types_active : [],
			years_active : [],

			ready: false,
			error: false,
	
		}

		// this.translate = this.translate.bind(this)
		

	}

	componentDidMount() {
		this.urlVars();
		this.fetchData();
		this.sticker();
	}

	sticker = function() {
		window.onscroll = function() {myFunction()};
		function myFunction() {
			var header = document.getElementById("header");
			var nav = document.getElementById("nav");
			if (window.pageYOffset >= header.clientHeight) {
				nav.classList.add("sticky")
			} else {
				nav.classList.remove("sticky");
			}		
		}
	}

	urlVars() {
		let lang = Helpers.getUrlVar("lang");
		if(lang==="de" || lang==="fr") {
			this.setState({
				lang: lang
			})
		}

		let testing = Helpers.getUrlVar("testing");		
		if(testing==="true") {
			this.setState({
				testing: true
			})
		}
	}

	preProcess(data) {

		data.items = Helpers.sortingArray(data.items,"nr",-1)
		
		let yrs_local = {};

		let items_local = [];
		let types_local = [];
		let years_local = [];

		data.items.map((item,k) => {
			if(item.status*1>=0) {

				item.note = ""

				if(item.image.length<3) {
					item.image = "./img/no-image.png"
					item.note = "no-image"
				}

				if(types_local.indexOf(item.type)<=-1) {
					types_local.push(item.type)
				}
				if(years_local.indexOf(item.year)<=-1) {
					years_local.push(item.year)
				}
				items_local.push(item);

				return false;
			}
			return false;
		})

		items_local.map((item,k) => {
			if(!yrs_local.hasOwnProperty(item.year)) {
				let year = {
					id:item.year,
					active:true,
					picItems: [],
					textItems : []
				}
				if(item.note==="no-image") {
					year.textItems.push(item)
				}
				else {
					year.picItems.push(item)
				}
				yrs_local[item.year] = year;
			}
			else {
				if(item.note==="no-image") {
					yrs_local[item.year].textItems.push(item)
				}
				else {
					yrs_local[item.year].picItems.push(item)
				}
			}
			return false;
		})

		years_local.sort().reverse()
		

		this.setState({
			items : items_local,
			yrs : yrs_local,
			types : types_local,
			years: years_local,
			types_active : types_local,
			years_active: [years_local[0]],
			ready : true
		})

	}

	toggle_menu(){

	}

	toggle_year(year) {
		// let years_active_local =  JSON.parse(JSON.stringify(this.state.years_active));
		// let index = years_active_local.indexOf(year);
		// if(index<=-1) {
		// 	years_active_local.push(year)
		// }
		// else {
		// 	years_active_local.splice(index,1)
		// }
		// this.setState({
		// 	years_active : years_active_local
		// })
		this.setState({
			years_active : year,
			all_years : false,

		})
		this.goTop()

	}

	toggle_all() {

		if(this.state.all_years===true) {
			this.setState({
				all_years : false,
				years_active : [this.state.years[0]]
			})
		}
		else {
			this.setState({
				all_years : true,
				years_active : this.state.years
			})
		}
		this.goTop()

	}

	toggle_type(type) {
		let types_active_local =  JSON.parse(JSON.stringify(this.state.types_active));
		let index = types_active_local.indexOf(type);
		if(index<=-1) {
			types_active_local.push(type)
		}
		else {
			types_active_local.splice(index,1)
		}
		if(types_active_local.length<=0) {
			types_active_local.push(type)
		}
		this.setState({
			types_active : types_active_local
		})
		this.goTop()
	}

	fetchData() {
		let d = new Date();
		let cacheBuster = (d.getMonth()+1)+"-"+d.getDate()+"-"+d.getHours()+"-"+d.getMinutes();

		let url = "./data/bulk.json?"+cacheBuster;

		fetch(url)

		.then(response => response.json())
		.then(
			(response) => {
				this.setState({
					update :  response.meta.update,
					}
				)
				this.preProcess(response.data)

				//
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log("error")
				this.setState({
					error: true,
					ready: false
				});
			}
		)
	}

	picItem(item,k) {
		if( (this.state.years_active.indexOf(item.year)>=0) &&  (this.state.types_active.indexOf(item.type)>=0) ) {
			return(
				<div className={"item type-"+item.type}
					key = {k}
				>
					<a 
						href={item.url}
						target='_blank' 
						rel="noreferrer"
					>
						<img className='img-container' src='./img/blank-120-63.gif' alt=''
								style={{
									backgroundImage:"url("+item.image+")"
								}}
						></img>
						<h2>{item.title}</h2>
						<div className='flag flag-type'>{item.type}</div>
						<div className='flag flag-date'>{item.date}</div>
						<div className='flag flag-year'>{item.year}</div>
						{/* <div className='flag flag-author'>{item.author}</div> */}
						<p className='description'>{item.description}</p>
						<div className='pseudoLink'>{item.url}</div>
						<div className='nr'>{item.nr}</div>
					</a>
				</div>
			)
		}
		else return false;
	}
	textItem(item,k) {
		if( (this.state.years_active.indexOf(item.year)>=0) &&  (this.state.types_active.indexOf(item.type)>=0) ) {

			return(
				<div className={"item type-"+item.type}
					key = {k}
				>
					<a 
					target='_blank' 
					rel="noreferrer"
					href={item.url}>
						<h2>{item.title}</h2>
						<div className='flag flag-type'>{item.type}</div>
						<div className='flag flag-date'>{item.date}</div>
						<div className='flag flag-year'>{item.year}</div>
						{/* <div className='flag flag-author'>{item.author}</div> */}
						<p className='description'>{item.description}</p>
						<div className='pseudoLink'>{item.url}</div>
						<div className='nr'>{item.nr}</div>
					</a>
				</div>
			)
		}
		else return false;
	}

	top() {
		return(
			<div className='top'
				onClick = 	{() => {
					this.goTop() 
					}
				}
			>Top</div>	
		)
		
		
	}

	goTop = () => {
		window.scrollTo(0, 0)
	}

	render() {
		return(
			<div tabIndex={0} className={"content"}
				
			>
				<header className="header" id='header'>
					<div className='header-container'>
						<h1 className='blick-header'>Blick Visuals Index</h1>
						<img 
							onClick = 	{() => {
								this.toggle_menu() 
								}
							}
						className="canihaz" alt="Filter" src="img/cheeseburger.png"></img>						
					</div>
					
				</header>
				{this.state.ready === false &&
					<div className='loading'>
						<div className='spinner-ring'><div></div><div></div><div></div><div></div></div>
					</div>
				}


				{this.state.ready === true &&

				<div className="main">
					<div id = 'nav' >
						<nav className='nav'>
							<ul>
								{
									this.state.years.map((year,k) => {
										let cls = "active";
										if(this.state.years_active.indexOf(year)<=-1) {
											cls = "inactive"
										}
										return(
										<li className={'flag flag-year flag-year-'+year +" "+cls}
											key={k}
											onClick = 	{() => {
												this.toggle_year(year) 
												}
											}
										>
											{year}
										</li>	
										)
								
									})
								}
								
								<li className={ this.state.all_years ? "flag flag-year flag-year-all active" : "flag flag-year flag-year-all inactive" }
									key={'all_years'}
									onClick = 	{() => {
										this.toggle_all() 
										}
									}
								>
								{"Alle"}

								</li>
					
								{
									this.state.types.map((type,k) => {
										let cls = "active";
										if(this.state.types_active.indexOf(type)<=-1) {
											cls = "inactive"
										}
										return(
										<li className={'flag flag-type flag-type-'+type +" "+cls}
											key={k}
											onClick = 	{() => {
												this.toggle_type(type) 
												}
											}
										>
											{type}
										</li>	
										)
								
									})
								}

								<li className='flag panoramas'>
									<a href='https://www.blick.ch/interaktiv/panorama_bilder/'
										target='blank'
										rel="noreferrer"
									>360°-FOTOS auf Blick.ch</a>
								</li>
								{/* <li className='flag blickHome'>
									<a href='https://www.blick.ch'
										target='blank'
										rel="noreferrer"
									>Blick.ch</a>
								</li> */}
							</ul>
						</nav>
					</div>
					<section className='section section-items'>
					{
						this.state.years.map((year,k) => {
							if (this.state.years_active.indexOf(year)>=0) {
								let yr = this.state.yrs[year];
								return(
									<div 
										key = {yr.id}
										className={'yr yr-'+yr.id}
									>
										<h1>
											{yr.id}
										</h1>
										<div className='items picItems'>
										{
											yr.picItems.map((item,k) => {
												return(
													this.picItem(item,k)
												)
											})
										}
										
										</div>
										<div className='items textItems'>
										{
											yr.textItems.map((item,k) => {
												return(
													this.textItem(item,k)
												)
											})
										}
										</div>
										{
											this.top()
										}
									</div>


									
								)
							}
							else {
								return false
							}
						
						
						})

					}
					</section>


					{/* <section className='section section-items'>

						{
							this.state.items.map((item,k) => {
								if( (this.state.years_active.indexOf(item.year)>=0) &&  (this.state.types_active.indexOf(item.type)>=0) ) {

									return(
										<div className={"item type-"+item.type}
											key = {k}
										>
											{
											item.note!="no-image" &&
											<a
												target='_blank'
												href={item.url}
											>
												<img className='img-container' src='./img/blank-120-63.gif' alt=''
													style={{
														backgroundImage:"url("+item.image+")"
													}}
												></img>
											</a>
											}
											<h2>{item.title}</h2>
											<div className='flag flag-type'>{item.type}</div>
											<div className='flag flag-date'>{item.date}</div>
											<div className='flag flag-year'>{item.year}</div>
											<div className='flag flag-author'>{item.author}</div>
											<p className='description'>{item.description}</p>
											<a className='link' target='_blank' href={item.url}>Weiter ▸</a>
										</div>
									)
								}

							})
						}
					</section> */}

			

				</div>

				}

				<footer className="blick-footer">
					© Blick Visuals 2022, rtp.
				</footer>
			</div>
		)
	}
}


export default Content;
